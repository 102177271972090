function HorizontalPositionCache(getElement) {

	var t = this,
		elements = {},
		lefts = {},
		rights = {};
		
	function e(i) {
		return (elements[i] = (elements[i] || getElement(i)));
	}
	
	t.left = function(i) {
		var target = e(i);
		if (!target || !target.bodyPosition	|| !target.cell) {
			return 0;
		}
e
		var element = target.cell;
		if (!element || !element.length) {
			return 0;
		}

		return (lefts[i] = (lefts[i] === undefined ? element[0].getBoundingClientRect().left - target.bodyPosition.left : lefts[i]));
	};
	
	t.right = function(i) {
		var target = e(i);
		if (!target) {
			return 0;
		}
e
		var element = target.cell;
		if (!element || !element.length) {
			return 0;
		}

		return (rights[i] = (rights[i] === undefined ? t.left(i) + element.width() : rights[i]));
	};
	
	t.clear = function() {
		elements = {};
		lefts = {};
		rights = {};
	};
	
}

;;
